import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { ActionProductsListModel } from "@/domain/models";
import { ActionProductsList } from "@/domain/useCases/actionProductsList";
import { Money } from "@/services/money/money";

export class RemoteActionProductsList implements ActionProductsList {
    private data: ActionProductsListModel[] = [];
    constructor(
        protected readonly httpGetClient: HttpClient<ActionProductsListModel[]>,
        protected readonly urlLoadProductsList: string = "action/products/listFromStock"
    ) {}
    async loadProductsList(): Promise<ActionProductsListModel[]> {
        const response = await this.httpGetClient.request({
            url: this.urlLoadProductsList,
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    const data = this.formatProductsList(response.body);
                    this.data = data;
                    return data;
                }
                return [] as ActionProductsListModel[];
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as ActionProductsListModel[];
            default:
                throw new UnexpectedError();
        }
    }

    formatProductsList(params: ActionProductsListModel[]) {
        if (!params.length) return [];
        const money = new Money();
        const update = params
            .map((param) => {
                return {
                    ...param,
                    valor_reais: money.convertToBRL(param.valor_reais),
                };
            })
            .sort((a, b) => {
                const nomeComparison = a.nome.localeCompare(b.nome);
                const valorA = parseFloat(a.valor_reais.replace(/\D/g, ""));
                const valorB = parseFloat(b.valor_reais.replace(/\D/g, ""));

                if (nomeComparison !== 0) {
                    return nomeComparison;
                }

                return valorA - valorB;
            });
        return update;
    }

    filterProductsList(value: string) {
        if (!this.data.length) return [];

        const update = this.data
            .filter((data) => {
                return JSON.stringify(data, [
                    "id",
                    "nome",
                    "tipo",
                    "valor_reais",
                    "total_disponivel",
                ])
                    .toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase());
            })
            .sort((a, b) => {
                const nomeComparison = a.nome.localeCompare(b.nome);
                const valorA = parseFloat(a.valor_reais.replace(/\D/g, ""));
                const valorB = parseFloat(b.valor_reais.replace(/\D/g, ""));

                if (nomeComparison !== 0) {
                    return nomeComparison;
                }

                return valorA - valorB;
            });

        return update;
    }
}
