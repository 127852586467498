import { Text, Flex, Box } from "alisson-application";
import { useEffect, useState } from "react";
import { Container as Button } from "@/presentation/components/Button";
import { useModal } from "@/main/contexts/modal";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { ActionListVoucher } from "@/presentation/components/ActionListVoucher";
import { useDetailAction } from "@/main/hooks/detailAction";

import imgCard from "@/presentation/assets/icone/card-plus-roxo.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { ActionProductsListModel } from "@/domain/models";
import { Loader } from "../Loader";
import { Container } from "./styles";
export function AddVoucher() {
    const { handleClose, setState, state } = useModal();
    const { voucher } = useDetailAction();
    const { setProductsChoice, productsChoice, fetchProduct } =
        useDetailActionVoucher();
    const [vouchers, setVouchers] = useState<ActionProductsListModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    function handleAddProducts(productChoice: ActionProductsListModel) {
        const hasProduct = productsChoice.find(
            (product) => product.id === productChoice.id
        );
        if (hasProduct) {
            const update = productsChoice.filter(
                (product) => product.id !== productChoice.id
            );
            setProductsChoice(update);
        } else {
            setProductsChoice([...productsChoice, productChoice]);
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetchProduct();
                setVouchers(response);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (search === null) return;
        setVouchers(voucher.filterProductsList(search));
    }, [search]);

    return (
        <Container>
            <Flex alignItems="center" mb="26px">
                <img src={imgCard} alt="" />
                <Text ml="10px" as="h5">
                    ADICIONAR VOUCHERS
                </Text>
            </Flex>

            <div className="container-input buscar">
                <input
                    type="text"
                    placeholder="Nome do voucher"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <div className="list-vouhcer">
                {loading ? (
                    <div className="loader">
                        <Loader />
                    </div>
                ) : (
                    vouchers.map((voucher) => (
                        <ActionListVoucher
                            key={voucher.id}
                            {...voucher}
                            onChange={(productChoice) => {
                                handleAddProducts(productChoice);
                            }}
                        />
                    ))
                )}

                <Box m="31px auto 0px" width="383px">
                    <Button
                        disabled={productsChoice.length === 0}
                        onClick={() => {
                            setState({
                                ...state,
                                selectVouchers: false,
                                selectVouchersQtd: true,
                            });
                        }}
                    >
                        Adicionar produtos
                    </Button>
                </Box>
            </div>

            <div className="container-close">
                <button onClick={() => handleClose("selectVouchers")}>
                    <img src={imgClose} alt="" />
                </button>
            </div>
        </Container>
    );
}
